<template>
  <div class="T">
    <!-- 搜索 -->
    <div class="suso">
      <el-form>
        <el-form-item>
          <el-input
            placeholder="输入表id"
            v-model.trim="cxyts.meter_id"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="block">
            <el-date-picker
              v-model="suntime"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="小区:" class="elform">
          <el-select
            v-model="cxyts.community_id"
            placeholder="请选择小区"
            size="small"
            :disabled="saveDisable"
            @change="selectQuarters(cxyts.community_id)"
          >
            <el-option
              v-for="item in quarters"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="楼栋:" class="elform">
          <el-select
            v-model="cxyts.building_id"
            placeholder="请选择楼栋"
            size="small"
            :disabled="saveDisable"
            @change="selectBuildings(cxyts.building_id)"
          >
            <el-option
              v-for="item in Buildings"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单元:" class="elform">
          <el-select
            v-model="cxyts.unit_id"
            placeholder="请选择单元"
            size="small"
            :disabled="saveDisable"
            @change="selectunit(cxyts.unit_id)"
          >
            <el-option
              v-for="item in units"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="房间:" class="elform">
          <el-select
            v-model="cxyts.room_id"
            placeholder="请选择房间"
            size="small"
            :disabled="saveDisable"
            @change="selectroom(cxyts.room_id)"
          >
            <el-option
              v-for="item in roomsfas"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-button
            class="elfomobtn"
            type="primary"
            @click="pay_search"
            size="small"
            >查 询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            class="elfomobtn"
            type="primary"
            @click="Refreshbtn"
            size="small"
            >刷 新</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 内容区域pc端 -->
    <div class="tab">
      <el-card>
        <el-table
          ref="table"
          :data="parData"
          tooltip-effect="dark"
          style="width: 100%"
          height="500"
          highlight-current-row
          :header-cell-style="{ background: '#df6124' }"
        >
          <!-- <el-table-column type="index" label="序号">
          </el-table-column> -->
          <el-table-column prop="meter_id" label="表号">
          </el-table-column>
          <el-table-column prop="username" label="用户名"> </el-table-column>
          <el-table-column prop="address" label="地址"> </el-table-column>
          <el-table-column prop="date" label="统计时间">
          </el-table-column>
          <el-table-column :label="'总数据：'+total_water_use" :name="total_water_use">
          <el-table-column prop="total_water_use" label="该时间所用流量">
          </el-table-column>
          </el-table-column>
          <el-table-column :label="'总数据：'+total_heat_use" :name="total_heat_use">
          <el-table-column prop="total_heat_use" label="该时间所用热量">
          </el-table-column>
          </el-table-column>
          <el-table-column :label="'总数据：'+total_cold_use" :name="total_cold_use">
          <el-table-column prop="total_cold_use" label="该时间所用冷量">
          </el-table-column>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>
  <script>
import { communities, buildings, units, rooms } from "@/api/userProfile"; // 小区楼栋单元房间接口
import { calorimeterStatisticalConsumptionData } from "@/api/calorimeterStatisticalConsumptionData"; // 热表接口
import Cookies from "js-cookie";
export default {
  data() {
    return {
      saveDisable: false, //是否禁用位置
      suntime: null, // 日期
      s_time:'暂无', // 时间
      total_water_use:'暂无', //该时间所用流量
      total_heat_use:'暂无', //该时间所用热量
      total_cold_use:'暂无', //该时间所用冷量
      assembledata:null,
      parData: [], //table存储数据
      // table
      famls: {
        token: null,
        meter_id: null,
      },
      // 下拉框
      // 获取小区
      quarters: [
        {
          id: null,
          name: null,
        },
      ],
      // 获取楼栋
      Buildings: [
        {
          id: null,
          name: null,
        },
      ],
      // 获取单元
      units: [
        {
          id: null,
          name: null,
        },
      ],
      // 获取房间
      roomsfas: [
        {
          id: null,
          name: null,
        },
      ],
      // 小区参数
      comities: {
        token: null,
      },
      munity: null,
      // 楼栋参数
      buings: {
        token: null,
        community_id: null,
      },
      unis: null,
      // 单元参数
      unistfs: {
        token: null,
        building_id: null,
      },
      rom: null,
      // 房间参数
      roomfs: {
        token: null,
        unit_id: null,
      },
      cxyts: {
        token: null,
        span: "day",
        start_time: null,
        end_time: null,
        community_id: null,
        building_id: null,
        unit_id: null,
        room_id: null,
      },
    };
  },
  created() {
    this.TabData();
    // 下拉框
    // 获取小区
    this.residential();
    // 获取楼栋
    this.Building();
    // 单元
    this.unit();
    // 房间
    this.room();
  },

  computed: {
    search() {
      return this.cxyts.meter_id;
    },
  },
  watch: {
    search(newValue) {
      if (newValue !== "") {
        //如果为空，执行方法获取list
        console.log("不为空");
        this.saveDisable = true;
      } else {
        this.saveDisable = false;
      }
    },
  },
  methods: {
    TabData() {
      this.famls.token = Cookies.get("token");
      calorimeterStatisticalConsumptionData(this.famls).then((res) => {
        console.log(res);
      });
    },
    // 获取小区
    residential() {
      this.comities.token = Cookies.get("token");
      communities(this.comities).then((res) => {
        console.log(res.data.data, "获取小区");
        this.quarters = res.data.data;
      });
    },
    selectQuarters(val) {
      this.munity = val; // 给楼栋的id
      this.cxyts.community_id = val; // 查询的id
      this.cxyts.building_id = null;
      this.cxyts.unit_id = null;
      this.cxyts.room_id = null;
      this.Building();
    },
    // 获取楼栋
    Building() {
      this.buings.token = Cookies.get("token");
      this.buings.community_id = this.munity;
      buildings(this.buings).then((res) => {
        console.log(res.data.data, "获取楼栋");
        this.Buildings = res.data.data;
      });
    },
    selectBuildings(val) {
      this.unis = val; // 给单元的id
      this.cxyts.building_id = val;
      this.cxyts.unit_id = null;
      this.cxyts.room_id = null;
      this.unit();
    },
    // 单元
    unit() {
      this.unistfs.token = Cookies.get("token");
      this.unistfs.building_id = this.unis;
      units(this.unistfs).then((res) => {
        console.log(res.data.data, "获取单元");
        this.units = res.data.data;
      });
    },
    selectunit(val) {
      console.log(val, "val3");
      this.rom = val;
      this.cxyts.unit_id = val;
      this.cxyts.room_id = null;
      this.room();
    },
    // 房间
    room() {
      this.roomfs.token = Cookies.get("token");
      this.roomfs.unit_id = this.rom;
      rooms(this.roomfs).then((res) => {
        console.log(res.data.data, "获取房间");
        this.roomsfas = res.data.data;
      });
    },
    selectroom(val) {
      console.log(val, "房间id");
      this.cxyts.room_id = val;
      this.cxyts.token = Cookies.get("token");
    },
    // 查询
    pay_search() {
      console.log(this.suntime, "日期");
      this.cxyts.start_time = this.suntime;
      this.cxyts.end_time = this.suntime;
      this.cxyts.token = Cookies.get("token");
      calorimeterStatisticalConsumptionData(this.cxyts).then((res) => {
        console.log(res)
        if (res.data.status === "success") {
          console.log(res.data.data[0])
          this.assembledata = res.data.data[0]
          this.s_time = this.assembledata.s_time
          this.total_water_use = this.assembledata.total_water_use
          this.total_heat_use = this.assembledata.total_heat_use
          this.total_cold_use = this.assembledata.total_cold_use
          this.parData = res.data.data[0].meter_data_list;
          this.total = res.data.count;
          this.$message({
            message: res.data.message,
            type: "success",
          });
        } else if (res.data.status === "error") {
          this.$message.error(res.data.message);
        }
      });
    },
    // 刷新
    Refreshbtn() {
      location.reload();
    },
  },
};
</script>
  
  <style lang="less" scoped>
// 搜索
.suso {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    .el-input {
      width: 200px;
    }
    .el-button {
      margin-left: 10px;
      background-color: #df6124;
      border: 0px;
    }
    .el-form-item {
      margin-bottom: 9px;
      margin-left: 10px;
      display: flex;
      .el-select {
        width: 150px;
      }
    }
  }
}
.tab {
  // 分页
  .el-pagination {
    ::v-deep .el-pagination__rightwrapper {
      float: left;
      margin-left: 20px;
    }
  }
  .el-card {
    border-radius: 10px;
    ::v-deep .el-card__body {
      padding: 0px;
    }
    ::v-deep .el-table thead {
      color: #fff;
      font-weight: 300;
    }
    ::v-deep .cell {
      font-family: myFont;
    }
  }
  ::v-deep .cell {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    text-align: center;
  }
  .el-button {
    padding: 10px;
    border: 1px solid #df6124;
    background-color: #df6124;
    color: #fff;
    letter-spacing: 2px;
  }
}
// // 滚动条大小设置
// ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
//   /*纵向滚动条*/
//   width: 5px;
//   /*横向滚动条*/
//   height: 15px;
// }
</style>
  